import React from 'react';
import { useLocation } from 'react-router-dom';
import './CancelPage.css'; // Import the CSS file

const CancelPage: React.FC = () => {
    return (
        <div className="cancel-page">
            <h1>Payment Cancelled</h1>
            <p>It looks like you cancelled the payment. Please try again if you wish to subscribe.</p>
        </div>
    );
};

export default CancelPage;

