import React from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="privacy-policy">
            <h1>TuCara ltd Customer Privacy Notice</h1>
            <p>This privacy notice tells you what to expect us to do with your personal information.</p>
            
            <h2>Contact Details</h2>
            <p>Email: <a href="mailto:support@tucara.co.uk">support@tucara.co.uk</a></p>

            <h2>What information we collect, use, and why</h2>
            <p>We collect or use the following information to provide services and goods, including delivery:</p>
            <ul>
                <li>Names and contact details</li>
                <li>Purchase or account history</li>
                <li>Payment details (including card or bank information for transfers and direct debits)</li>
                <li>Account information</li>
                <li>Website user information (including user journeys and cookie tracking)</li>
                <li>Information relating to compliments or complaints</li>
            </ul>
            <p>We collect or use the following information for the operation of customer accounts and guarantees:</p>
            <ul>
                <li>Names and contact details</li>
                <li>Payment details (including card or bank information for transfers and direct debits)</li>
                <li>Purchase history</li>
                <li>Account information, including registration details</li>
            </ul>

            <h2>Lawful Bases</h2>
            <p>Our lawful bases for collecting or using personal information to provide services and goods are:</p>
            <ul>
                <li>Consent</li>
            </ul>
            <p>Our lawful bases for collecting or using personal information for the operation of customer accounts and guarantees are:</p>
            <ul>
                <li>Consent</li>
            </ul>

            <h2>Where we get personal information from</h2>
            <p>People directly</p>

            <h2>How long we keep information</h2>
            <table>
                <thead>
                    <tr>
                        <th>Data Category</th>
                        <th>Retention Period</th>
                        <th>Justification</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Names and Contact Details</td>
                        <td>7 years after last interaction</td>
                        <td>Legal and business requirements</td>
                    </tr>
                    <tr>
                        <td>Purchase or Account History</td>
                        <td>7 years after transaction</td>
                        <td>Financial and auditing purposes</td>
                    </tr>
                    <tr>
                        <td>Payment Details</td>
                        <td>7 years after transaction</td>
                        <td>Financial and auditing purposes</td>
                    </tr>
                    <tr>
                        <td>Account Information</td>
                        <td>7 years after account closure</td>
                        <td>Legal and business requirements</td>
                    </tr>
                    <tr>
                        <td>Website User Information</td>
                        <td>2 years after last activity</td>
                        <td>Analytics and service improvement</td>
                    </tr>
                    <tr>
                        <td>Information Relating to Compliments or Complaints</td>
                        <td>7 years after resolution</td>
                        <td>Legal and business requirements</td>
                    </tr>
                </tbody>
            </table>

            <h2>Who we share information with</h2>
            <h3>Data processors</h3>
            <p><strong>Auth0</strong></p>
            <p>This data processor does the following activities for us: They manage the authentication and authorisation processes for the application</p>
            <p><strong>Stripe</strong></p>
            <p>This data processor does the following activities for us: They process payments and subscriptions</p>

            <h3>Others we share personal information with</h3>
            <p>Organisations we’re legally obliged to share personal information with</p>

            <h2>Sharing information outside the UK</h2>
            <p>Where necessary, we may transfer personal information outside of the UK. When doing so, we comply with the UK GDPR, making sure appropriate safeguards are in place. Please contact us for more information.</p>
            <p>Where necessary, our data processors may share personal information outside of the UK. When doing so, they comply with the UK GDPR, making sure appropriate safeguards are in place. Please contact us for more information.</p>

            <h2>Your data protection rights</h2>
            <p>Under data protection law, you have rights including:</p>
            <ul>
                <li>Your right of access - You have the right to ask us for copies of your personal data.</li>
                <li>Your right to rectification - You have the right to ask us to rectify personal data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</li>
                <li>Your right to erasure - You have the right to ask us to erase your personal data in certain circumstances.</li>
                <li>Your right to restriction of processing - You have the right to ask us to restrict the processing of your personal data in certain circumstances.</li>
                <li>Your right to object to processing - You have the right to object to the processing of your personal data in certain circumstances.</li>
                <li>Your right to data portability - You have the right to ask that we transfer the personal data you gave us to another organisation, or to you, in certain circumstances.</li>
                <li>Your right to withdraw consent – When we use consent as our lawful basis you have the right to withdraw your consent.</li>
            </ul>
            <p>You don’t usually need to pay a fee to exercise your rights. If you make a request, we have one calendar month to respond to you.</p>
            <p>To make a data protection rights request, please contact us using the contact details at the top of this privacy notice.</p>

            <h2>How to complain</h2>
            <p>If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice.</p>
            <p>If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the ICO.</p>
            <p>The ICO’s address:</p>
            <address>
                Information Commissioner’s Office<br />
                Wycliffe House<br />
                Water Lane<br />
                Wilmslow<br />
                Cheshire<br />
                SK9 5AF
            </address>
            <p>Helpline number: <a href="tel:03031231113">0303 123 1113</a></p>
            <p>Website: <a href="https://www.ico.org.uk/make-a-complaint" target="_blank" rel="noopener noreferrer">https://www.ico.org.uk/make-a-complaint</a></p>

            <p><strong>Last updated:</strong> 10 June 2024</p>
        </div>
    );
};

export default PrivacyPolicy;
