import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import "./App.css";
import LandingPage from './components/LandingPage';
import LogoutButton from './components/LogoutButton';
import MainSubscriptionButton from './components/MainSubscriptionButton';
import SupportButton from './components/SupportButton'
import StoryDisplay from './components/StoryDisplay';
import CheckoutPage from './components/CheckoutPage';
import SuccessPage from './components/SuccessPage';
import CancelPage from './components/CancelPage';
import TermsOfService from './components/TermsOfService'; 
import PrivacyPolicy from './components/PrivacyPolicy';


const baseUrl = 'https://learningstoryserver.azurewebsites.net/api'; /*"http://localhost:8000/api";*/ 

function App() {
    const [topic, setTopic] = useState('');
    const [age, setAge] = useState('');
    const [story, setStory] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [showSubscribeButton, setShowSubscribeButton] = useState(false);
    const [isSubscriber, setIsSubscriber] = useState(false);
    const [errors, setErrors] = useState({ topic: false, age: false });

    const { isAuthenticated, getAccessTokenSilently, logout, user } = useAuth0();

    useEffect(() => {
        const fetchUserData = async () => {
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(`${baseUrl}/user`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            const userData = await response.json();
            setIsSubscriber(userData.is_subscriber);
        };

        if (isAuthenticated) {
            fetchUserData();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    const handleGeneratePil = async () => {

        const newErrors = {
            topic: topic.trim() === '',
            age: age.trim() === ''
        };
        setErrors(newErrors);

        if (newErrors.topic || newErrors.age) {
            return;
        }

        setIsGenerating(true);
        setStory('');  // Clear the story before starting a new one

        const learning = `I would like to learn about ${topic}. I am ${age} years old.`;
        const payload = { content: learning };

        try {
            const accessToken = await getAccessTokenSilently();
            const response = await fetch(`${baseUrl}/start-story/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const data = await response.json();

            if (data.status === "limit_reached") {
                setShowSubscribeButton(true);
                setStory(data.message);
                setIsGenerating(false);
            } else if (data.status === "success") {
                const threadId = data.thread_id;

                const eventSource = new EventSource(`${baseUrl}/stream-story/${threadId}?token=${accessToken}`);                
                eventSource.onmessage = function(event) {
                    try {
                        const parsedData = JSON.parse(event.data);

                        if (parsedData.message) {
                            setStory(prevStory => prevStory + '\n\n' + parsedData.message);
                        }

                        if (parsedData.status) {
                            setStory(prevStory => prevStory + `\n\nRun Status: ${parsedData.status}`);
                        }

                        if (parsedData.error) {
                            setStory(`Error: ${parsedData.error}`);
                            eventSource.close();
                        }
                    } catch (error) {
                        console.error("Failed to parse event data:", error);
                        setIsGenerating(false);
                        eventSource.close();
                    }
                };

                eventSource.onerror = function(event) {
                    console.error("EventSource failed:", event);
                    setIsGenerating(false);
                    eventSource.close();
                };
            } else {
                throw new Error(data.message);
            }

        } catch (error) {
            if (error instanceof Error) {
                console.error(`Failed to generate story: ${error.message}`);
                setStory(`Error: ${error.message}`);
                setIsGenerating(false);
            }
        }
    };

    const title = "Learning Story";
    const titleLetters = title.split('').map((letter, index) => (
        <span key={index} style={{ animationDelay: `${index * 0.4}s` }}>
            {letter}
        </span>
    ));

    return (
        <Router>
            <Routes>
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/cancel" element={<CancelPage />} />
                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/" element={
                    !isAuthenticated ? <LandingPage /> : (
                        <div className="App">
                            <div className="App-header">
                                <div className="Banner">
                                    <div className="LoggedInButtons">
                                        <LogoutButton />
                                        {!isSubscriber && <MainSubscriptionButton />}                                        
                                        <SupportButton />
                                    </div>
                                    <h1 className={isGenerating ? "animating" : ""}>{titleLetters}</h1>
                                </div>
                                <div className="Controls-Container">
                                    <div className="input-group">
                                        <div className={`input-group1 ${errors.topic ? 'error' : ''}`}>
                                            <span>I would like to learn about </span>
                                            <input
                                                type="text"
                                                value={topic}
                                                onChange={(e) => setTopic(e.target.value)}
                                                placeholder="how gravity works"
                                            />
                                            {errors.topic && <span className="error-message">Tell us what you'd like to learn</span>}
                                        </div>
                                        <div className={`input-group2 ${errors.age ? 'error' : ''}`}>
                                            <span>I am</span>
                                            <input
                                                type="number"
                                                value={age}
                                                onChange={(e) => setAge(e.target.value)}
                                                placeholder=""
                                            />
                                            <span>years old.</span>
                                            {errors.age && <span className="error-message">How old are you? Or if you are a parent, how old is your child?</span>}
                                        </div>
                                    </div>
                                    {showSubscribeButton ? (
                                        <div className='limit-reached-container'>
                                            <CheckoutPage />
                                        </div>
                                    ) : (
                                        !isGenerating && (
                                            <button className="GenerateButton" onClick={handleGeneratePil}>Write me a story!</button>
                                        )                                    
                                    )}
                                </div>
                            </div>
                            <StoryDisplay story={story} />
                        </div>
                    )
                } />
            </Routes>
        </Router>
    );
}

export default App;
