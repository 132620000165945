import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import './CheckoutPage.css';
import { useAuth0 } from '@auth0/auth0-react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface CheckoutButtonProps {
    paymentPlan: string;
    email: string;
    disabled?: boolean;
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({ paymentPlan, email, disabled }) => {
    const { getAccessTokenSilently } = useAuth0();

    const handleClick = async () => {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch('https://learningstoryserver.azurewebsites.net/api/create-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ payment_plan: paymentPlan, email: email }),
        });
        const session = await response.json();
        const stripe = await stripePromise;
        await stripe!.redirectToCheckout({ sessionId: session.id });
    };

    return (
        <button className="SubscribeButton" onClick={handleClick} disabled={disabled}>
            Subscribe {paymentPlan}
        </button>
    );
};

export default CheckoutButton;
