import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './SuccessPage.css'; // Import the CSS file

interface SessionData {
    customer_details: {
        name: string;
        email: string;
    };
}

const SuccessPage: React.FC = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [sessionData, setSessionData] = useState<SessionData | null>(null);
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const sessionId = query.get('session_id');

        const fetchSession = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const response = await fetch(`https://learningstoryserver.azurewebsites.net/api/checkout-session?session_id=${sessionId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                });
                const data = await response.json();
                setSessionData(data);
            } catch (error) {
                console.error('Error fetching session:', error);
            }
        };

        if (sessionId) {
            fetchSession();
        }

        const timer = setTimeout(() => {
            window.location.href = "https://www.learningstory.co.uk"; 
        }, 4000);

        return () => clearTimeout(timer);
    }, [location.search, getAccessTokenSilently]);

    return (
        <div className="success-page">
            <h1>Thank you for your purchase!</h1>
            <p>You will be redirected to the homepage shortly.</p>
            {sessionData && (
                <div className="customer-details">
                    <p>Customer: {sessionData.customer_details.name}</p>
                    <p>Email: {sessionData.customer_details.email}</p>
                </div>
            )}
        </div>
    );
};

export default SuccessPage;
