// CookiePolicyModal.tsx
import React from 'react';
import './CookiePolicyModal.css';

interface CookiePolicyModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CookiePolicyModal: React.FC<CookiePolicyModalProps> = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="cookie-policy-modal-overlay">
            <div className="cookie-policy-modal">
                <button className="close-button" onClick={onClose}>&times;</button>
                <div className="cookie-policy-content">
                    <h1>Cookie Policy</h1>
                    <p>We use cookies to enhance your experience on our website. This policy explains what cookies are, how we use them, and your options regarding their use.</p>

                    <h2>What Are Cookies?</h2>
                    <p>Cookies are small text files that are stored on your device by your web browser. They allow websites to remember your actions and preferences over a period of time.</p>

                    <h2>How We Use Cookies</h2>
                    <p>We use cookies for various purposes, including:</p>
                    <ul>
                        <li>Essential cookies: Necessary for the website to function properly.</li>
                        <li>Performance cookies: Help us understand how visitors interact with our website by collecting and reporting information anonymously.</li>
                        <li>Functionality cookies: Allow the website to remember choices you make and provide enhanced, personalized features.</li>
                    </ul>

                    <h2>Your Choices</h2>
                    <p>You can manage your cookie preferences through your web browser settings. Most browsers allow you to control cookies, including blocking or deleting them. However, if you choose to disable cookies, some features of our website may not function properly.</p>

                    <h2>More Information</h2>
                    <p>If you have any questions about our use of cookies, please contact us at support@tucara.co.uk.</p>
                </div>
            </div>
        </div>
    );
};

export default CookiePolicyModal;
