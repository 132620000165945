import React from 'react';
import './StoryDisplay.css';

interface StoryDisplayProps {
    story: string;
}

function StoryDisplay({ story }: StoryDisplayProps) {
    return (
        <div className="w-3/4 mx-auto p-6 bg-yellow-50 rounded-lg shadow-md my-6">
            <h2 className="text-3xl font-bold mb-4 text-center"></h2>
            <div className="text-2xl leading-relaxed whitespace-pre-line font-serif">
                {story && <p className="fade-in">{story}</p>}
            </div>
        </div>
    );
}

export default StoryDisplay;
