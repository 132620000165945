// SupportButton.tsx
import React from 'react';
import './LoggedInButtons.css';

const SupportButton: React.FC = () => {
    const handleSupportClick = () => {
        window.location.href = 'mailto:support@tucara.co.uk?subject';
    };

    return (
        <button className="support-button" onClick={handleSupportClick}>
            Support
        </button>
    );
};

export default SupportButton;
