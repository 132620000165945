import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LandingPage.css';

const GetStartedButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() => loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup'
        }
      })}
      className="get-started-button"
    >
      Get Started
    </button>
  );
};

export default GetStartedButton;