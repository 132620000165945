// MainSubscriptionButton.tsx
import React, { useState } from "react";
import ReactDOM from "react-dom";
import CheckoutPage from "./CheckoutPage";
import './LoggedInButtons.css';


const MainSubscriptionButton: React.FC = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div>
            <button className="MainSubscriptionButton" onClick={openPopup}>
                Subscribe Now
            </button>
            {isPopupOpen && (
                ReactDOM.createPortal(
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <button className="close-button" onClick={closePopup}>
                                &times;
                            </button>
                            <CheckoutPage />
                        </div>
                    </div>,
                    document.body
                )
            )}
        </div>
    );
};

export default MainSubscriptionButton;
