import React from 'react';
import './LandingPage.css';
import LoginButton from './LoginButton';
import learningStoryImage from '../LearningStory.png';
import CreateAccountButton from './CreateAccountButton';
import GetStartedButton from './GetStartedButton';
import CookieConsentBanner from './CookieConsentBanner';

const LandingPage: React.FC = () => {
    return (
        <div className="landing-page">
            <header className="header">
                <img src={learningStoryImage} alt="Learning Story" className="logo" />
                <div className='auth-buttons-container'>
                    <LoginButton />
                    <CreateAccountButton />
                </div>
            </header>
            <main className="main-content">
                <div className='left-container'>
                    <h1 className="main-title">Teach Your Child Anything With AI Stories</h1>
                    <p className="main-subtitle">Bond with your little one while they learn. Generate endless imaginative educational stories with one click.</p>
                    <GetStartedButton />
                </div>
                <div className='right-container'>
                    <div className="video-responsive">
                        <iframe 
                            width="560" 
                            height="315" 
                            src="https://www.youtube.com/embed/WtK7uOvaSEU?si=fuu0HYE54BiftY28" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            referrerPolicy="strict-origin-when-cross-origin" 
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
            </main>
            <section className="testimonials">
                <div className="testimonial-cards">
                    <div className="testimonial-card">
                    <div className="stars">★★★★★</div>
                        <p>"Love love love it"</p>
                        <h3>Josh Bamford</h3>
                    </div>
                    <div className="testimonial-card">
                    <div className="stars">★★★★★</div>
                        <p>"Learning Story has become part of our bedtime routine. My little boy loves the magic of getting a completely new story every night and we have both learned so much since we started using it! Would definitely recommend."</p>
                        <h3>Matthew Drummond</h3>
                    </div>
                    <div className="testimonial-card">
                    <div className="stars">★★★★★</div>
                        <p>"Finn seems to love our story time almost as much as I do. Can't think of a better way for us to bond together."</p>
                        <h3>Lisa Watt</h3>
                    </div>
                </div>
            </section>
            <footer className="footer">
                <CookieConsentBanner />
                <div className="footer-content">
                    <p>&copy; 2024 LearningStory. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
