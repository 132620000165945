import React, { useState } from "react";
import CheckoutButton from "./CheckoutButton";
import './CheckoutPage.css';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

const CheckoutPage: React.FC = () => {
    const { user } = useAuth0();
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    const handleCheckboxChange = () => {
        setAgreedToTerms(!agreedToTerms);
    };

    return (
        <div className='CheckoutContainer'>
            <h1>Choose Your Plan</h1>
            <div className='CheckoutOptions'>
                <div className='CheckoutOption'>
                    <h2>Monthly Plan</h2>
                    <p>Price: £4.99/month</p>
                    <p>Unlimited story generation</p>
                    <CheckoutButton paymentPlan="monthly" email={user!.email!} disabled={!agreedToTerms} />
                </div>
                <div className='CheckoutOption'>
                    <h2>Annual Plan</h2>
                    <p>Price: £45/year</p>
                    <p>Unlimited story generation</p>
                    <p>24% Discount</p>
                    <CheckoutButton paymentPlan="annual" email={user!.email!} disabled={!agreedToTerms} />
                </div>
            </div>
            <div className="terms-conditions">
                <input
                    type="checkbox"
                    checked={agreedToTerms}
                    onChange={handleCheckboxChange}
                />
                <label>
                    I agree to the <Link to="/terms">terms and conditions</Link> and the <Link to="/privacy-policy">privacy policy</Link>.
                </label>
            </div>
        </div>
    );
}

export default CheckoutPage;
