import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './AuthButtons.css';

const CreateAccountButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
      <button
        onClick={() => loginWithRedirect({
          authorizationParams: {
            screen_hint: 'signup'
          }
        })}
        className="Create-account-button"
      >
        Create Account
      </button>
  );
};

export default CreateAccountButton;
