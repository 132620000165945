import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './AuthButtons.css';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return(
   <button onClick={() => loginWithRedirect({
    authorizationParams: {
      audience: 'https://dev-lp0jzxgv0auc31ie.us.auth0.com/api/v2/',
      scope: 'read:messages'
    }
    })} className='Login-button'>
    Log In
   </button>
  )
};

export default LoginButton;
