// CookieConsentBanner.tsx
import React, { useState, useEffect } from 'react';
import CookiePolicyModal from './CookiePolicyModal';
import { Link } from 'react-router-dom';
import './CookieConsentBanner.css';

const CookieConsentBanner: React.FC = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const consent = getCookie('cookieConsent');
        if (!consent) {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        setCookie('cookieConsent', 'true', 365);
        setShowBanner(false);
    };

    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()!.split(';').shift();
    };

    const setCookie = (name: string, value: string, days: number) => {
        const expires = new Date();
        expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
        document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    };

    if (!showBanner) return null;

    return (
        <div>
            <div className="cookie-consent-banner">
                <p>
                    We use cookies to ensure you get the best experience on our website. By continuing to use our site, you accept our <button className="cookie-policy-link" onClick={() => setIsModalOpen(true)}>cookie policy</button> and <Link to="/privacy-policy" className="privacy-policy-link">privacy policy</Link>.
                </p>
                <button className='cookie-consent-banner-button' onClick={acceptCookies}>Accept</button>
            </div>
            <CookiePolicyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </div>
    );
};

export default CookieConsentBanner;
